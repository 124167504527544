import React from "react";
import "./Vans.css"
import VabNavbar from "../../components/VanNavBar";
import RetinaImage from "../../components/RetinaImage";
import AutoGallery from "../../components/AutoGallery";

import {Col, Container, Row} from "react-bootstrap";

import main from "../../images/van2/main.png";
import main_l from "../../images/van2/main@2x.png";
import day_l from "../../images/van2/day@2x.png";
import night_l from "../../images/van2/night@2x.png";
import big from "../../images/van2/big.png";
import big_l from "../../images/van2/big@2x.png";
import ExpandImage from "../../components/ExpandImage";

const images = [
    '/images/van2/WBRV-10327.jpg',
    '/images/van2/WBRV-10362.jpg',
    '/images/van2/WBRV-10376.jpg',
    '/images/van2/WBRV-10383.jpg',
    '/images/van2/WBRV-10389.jpg',
    '/images/van2/WBRV-10438.jpg',
    '/images/van2/WBRV-10442.jpg',
    '/images/van2/WBRV-10447.jpg',
    '/images/van2/WBRV-10457.jpg',
    '/images/van2/WBRV-10483.jpg',
    '/images/van2/WBRV-10490.jpg',
    '/images/van2/WBRV-10498.jpg',
    '/images/van2/WBRV-10513.jpg',
    '/images/van2/WBRV-10523.jpg',
    '/images/van2/WBRV-10552.jpg',
    '/images/van2/WBRV-10586.jpg',
    '/images/van2/WBRV-10635.jpg',
    '/images/van2/WBRV-10646.jpg',
    '/images/van2/WBRV-10687.jpg',
    '/images/van2/WBRV-10698.jpg',
];

function Van2() {
    return (
        <div className="vanWrapper">
            <VabNavbar/>
            <div className="vanMain">
                <RetinaImage smallImage={main} largeImage={main_l} altText="Gen 5 Shower"/>
            </div>

            <div id="intro" className="vanSection introSection">
                <Container>
                    <div className="vanTitle">Gen 5 Shower</div>
                    <div className="vanModel">Toyota HiAce Generation 5 Model ZX, Jumbo</div>
                    <div className="vanSubText">This campervan is built on a Generation 5 Toyota Hiace, which is still
                        being produced new in Japan, in both 2WD and 4WD, with Petrol or Diesel options. The camper kit
                        features a fully self-contained shower and toilet module, this campervan is ideal for couples or
                        solo travellers, designed with off grid camping in mind
                    </div>
                </Container>
            </div>

            <div id="layout" className="vanSection layoutSection">
                <Container>
                    <div className="vanSectionHeading">CAMPERVAN LAYOUT</div>
                    <div className="vanSubText">While the Gen 5 offers the same internal build space as the Gen 6 model,
                        externally the Generation 5 is approximately 500mm shorter than the Generation 6, due to the Gen
                        6 having the motor in front of the driver and passenger.
                    </div>
                    <br/>
                    <div className="vanSubText">The campervan kit can be installed in both new and pre-owned Toyota
                        HiAce Gen 5. We offer the option to supply a van for you, or you can bring your own vehicle to
                        us for the installation.
                    </div>
                    <div className="layoutImages">
                        <Row>
                            <Col lg={6} md={12}>
                                <ExpandImage image={day_l} altText="Gen 5 Shower Day Layout"/>
                            </Col>
                            <Col lg={6} md={12}>
                                <ExpandImage image={night_l} altText="Gen 5 Shower Night Layout"/>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>

            <div className="vanSection videoSection">
                <RetinaImage smallImage={big} largeImage={big_l} altText="Gen 5 Shower Inside View"/>
            </div>

            <div id="specs" className="vanSection specsSection">
                <Container>
                    <div className="vanSectionHeading">SPECIFICATIONS</div>
                    <Row>
                        <Col lg={3} md={6} xs={12}>
                            <ul>
                                <li>External Length: 5400mm</li>
                                <li>External Width: 1900mm</li>
                                <li>External Height: 2750mm (includes fibreglass roof)</li>
                                <li>Internal Width: 1740mm</li>
                                <li>Internal Height: 1960mm</li>
                                <li>Crawl through between shower and front cabinet</li>
                                <li>Shower module with cassette toilet, hand basin, hot and cold-water tap</li>
                                <li>Trumma 14L hot water unit 2-way LPG gas or 240v</li>
                                <li>Dometic sink with hot and cold-water tap, glass opening lid</li>
                                <li>Dometic hob 2 burner gas with glass opening lid.</li>
                            </ul>
                        </Col>
                        <Col lg={3} md={6} xs={12}>
                            <ul>
                                <li>Dometic fridge 80 litre 2-way 240v/12v</li>
                                <li>Double 240v power point</li>
                                <li>Redarc 200watt solar setup</li>
                                <li>1 x Lithium LiFePo4 Battery with 110Ah</li>
                                <li>Redarc 25amp Charging system, with van DC/DC and MPPT solar, plus a 240v charging system</li>
                                <li>240v Mains connection with 10-meter power cord</li>
                                <li>12v LED lights throughout</li>
                                <li>3m Fiamma awning</li>
                            </ul>
                        </Col>
                        <Col lg={3} md={6} xs={12}>
                            <ul>
                                <li>2 Berth</li>
                                <li>Twin USB outlet</li>
                                <li>Dunlop foam squabs</li>
                                <li>Thermal backed curtains</li>
                                <li>Interior walls lined with marine hull-liner</li>
                                <li>Tinted windows</li>
                                <li>Two 4kg gas bottles</li>
                                <li>12v pressure water pump</li>
                            </ul>
                        </Col>
                        <Col lg={3} md={6} xs={12}>
                            <ul>
                                <li>Roof vent with night blind and fly screen</li>
                                <li>Water and waste tank monitors</li>
                                <li>65 Litre water and 65 Litre waste tanks mounted under van</li>
                                <li>Bed measurements, 2 x 620 x 1900 singles or 1300 x 1720 sideways double or 1720 x
                                    1900 long ways Queen
                                </li>
                                <li>Includes all certificates, Gas, Electrics, Self-containment, and Engineering</li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div id="extras" className="vanSection extrasSection">
                <Container>
                    <div className="vanSectionAltHeading">OPTIONAL EXTRAS (AT ADDITIONAL COST)</div>
                    <div className="vanAltSubText">We provide additional features to the standard builds upon request.
                        Some of these include:
                    </div>
                    <Row>
                        <Col lg={4} md={6} xs={12}>
                            <ul>
                                <li>Cabin Heater</li>
                                <li>Tow Bar</li>
                                <li>TV/Satellite Dish</li>
                            </ul>
                        </Col>
                        <Col lg={4} md={6} xs={12}>
                            <ul>
                                <li>Outdoor Table</li>
                                <li>Fly Screens</li>
                                <li>Rear sliding window</li>
                            </ul>
                        </Col>
                        <Col lg={4} md={6} xs={12}>
                            <ul>
                                <li>Electric Roof Vent</li>
                                <li>Inverter</li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div id="gallery" className="vanSection gallerySection">
                <Container>
                    <div className="vanSectionBlackHeading">GALLERY</div>
                    <div className="vanBlackSubText">Tap image to enlarge.</div>
                    <AutoGallery images={images}/>
                </Container>
            </div>
        </div>

    )
}

export default Van2;