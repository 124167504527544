import './Footer.css';
import React from "react";
import logo from "../logo.svg";
import {Container, Row} from "react-bootstrap";

function Footer() {
    return (
        <div className="footer">
            <Container>
                <Row>
                    <img className="logo" src={logo} alt="WBR logo"/>
                </Row>
                <Row>
                    <div className="footerText">© 2024 West Built Recreational Vehicles</div>
                </Row>
            </Container>
        </div>
    )
}

export default Footer;