import React from "react";
import "./Vans.css"
import VabNavbar from "../../components/VanNavBar";
import RetinaImage from "../../components/RetinaImage";
import AutoGallery from "../../components/AutoGallery";

import {Col, Container, Row} from "react-bootstrap";

import main from "../../images/van3/main.png";
import main_l from "../../images/van3/main@2x.png";
import day_l from "../../images/van3/day@2x.png";
import night_l from "../../images/van3/night@2x.png";
import big from "../../images/van3/big.png";
import big_l from "../../images/van3/big@2x.png";
import ExpandImage from "../../components/ExpandImage";

const images = [
    '/images/van3/WBRV-10720.jpg',
    '/images/van3/WBRV-10738.jpg',
    '/images/van3/WBRV-10792.jpg',
    '/images/van3/WBRV-10818.jpg',
    '/images/van3/WBRV-10835.jpg',
    '/images/van3/WBRV-10846.jpg',
    '/images/van3/WBRV-10886.jpg',
    '/images/van3/WBRV-10909.jpg',
    '/images/van3/WBRV-10911.jpg',
    '/images/van3/WBRV-10935.jpg',
    '/images/van3/WBRV-10951.jpg',
    '/images/van3/WBRV-10956.jpg',
    '/images/van3/WBRV-10980.jpg',
    '/images/van3/WBRV-10994.jpg',
    '/images/van3/WBRV-10997.jpg',
    '/images/van3/WBRV-11001.jpg',
    '/images/van3/WBRV-11012.jpg',
    '/images/van3/WBRV-11046.jpg',
    '/images/van3/WBRV-11089.jpg',
    '/images/van3/WBRV-11195.jpg',
];

function Van3() {
    return (
        <div className="vanWrapper">
            <VabNavbar/>
            <div className="vanMain">
                <RetinaImage smallImage={main} largeImage={main_l} altText="Gen 6 Shower"/>
            </div>

            <div id="intro" className="vanSection introSection">
                <Container>
                    <div className="vanTitle">Gen 6 Shower</div>
                    <div className="vanModel">Toyota HiAce Generation 6 ZX Jumbo</div>
                    <div className="vanSubText">Introducing the latest Gen 6 Hiace conversion from WBR, a fully
                        self-contained campervan that combines Toyota’s renowned reliability with a 5-star safety
                        rating. This camper offers exceptional comfort, value, and dependability, providing impressive
                        value for your investment.
                    </div>
                </Container>
            </div>

            <div id="layout" className="vanSection layoutSection">
                <Container>
                    <div className="vanSectionHeading">CAMPERVAN LAYOUT</div>
                    <div className="vanSubText">The campervan kit can be installed in both new and pre-owned Toyota
                        HiAce Gen 6. We offer the option to supply a van for you, or you can bring your own vehicle to
                        us for the installation. The Generation 6 model is a 2.8L turbo diesel in 2WD (rear wheel drive)
                        We can supply you a brand-new Gen 6 Toyota Hiace.
                    </div>
                    <br/>
                    <div className="vanSubText">This conversion accommodates 2 people with walk through, rotating
                        passenger seat, and includes spacious front overhead storage and lockers. 
                        The camper kit features a fully self-contained shower and toilet module.
                    </div>
                    <div className="layoutImages">
                        <Row>
                            <Col lg={6} md={12}>
                                <ExpandImage image={day_l} altText="Gen 6 Shower Day Layout"/>
                            </Col>
                            <Col lg={6} md={12}>
                                <ExpandImage image={night_l} altText="Gen 6 Shower Night Layout"/>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>

            <div className="vanSection videoSection">
                <RetinaImage smallImage={big} largeImage={big_l} altText="Gen 6 Shower Inside View"/>
            </div>

            <div id="specs" className="vanSection specsSection">
                <Container>
                    <div className="vanSectionHeading">SPECIFICATIONS</div>
                    <Row>
                        <Col lg={3} md={6} xs={12}>
                            <ul>
                                <li>External Length: 5900mm</li>
                                <li>External Width: 1980mm</li>
                                <li>External Height: 2850mm (includes fibreglass roof)</li>
                                <li>Internal Width: 1770mm</li>
                                <li>Internal Height: 2030mm</li>
                                <li>Walk through to front seats with rotating front passenger seat</li>
                                <li>Shower module with cassette toilet, hand basin, hot and cold-water tap</li>
                                <li>Trumma 14L hot water unit 2-way LPG gas or 240v</li>
                                <li>Sink with hot and cold-water tap and chopping board</li>
                            </ul>
                        </Col>
                        <Col lg={3} md={6} xs={12}>
                            <ul>
                                <li>Flip-up bench extensions on either side of the kitchen unit</li>
                                <li>Dometic hob 2 burner gas with glass opening lid</li>
                                <li>Dometic fridge 80 litre 2-way 240v/12v</li>
                                <li>Double 240v power point</li>
                                <li>Redarc 200watt solar setup</li>
                                <li>1 x Lithium LiFePo4 Battery with 110Ah</li>
                                <li>Redarc 25Amp charging system, with van DC/DC and solar for house batteries</li>
                                <li>240v Mains charging system for house batteries</li>
                            </ul>
                        </Col>
                        <Col lg={3} md={6} xs={12}>
                            <ul>
                                <li>240v Mains connection with 10-meter power cord</li>
                                <li>Twin USB outlet</li>
                                <li>12v LED lights throughout</li>
                                <li>3m Fiamma awning</li>
                                <li>Roof vent with night blind and fly screen</li>
                                <li>Two 4kg gas bottles</li>
                                <li>12v pressure water pump</li>
                                <li>Water and waste tank monitors</li>
                                <li>85 Litre water and 85 Litre waste tanks mounted under van</li>
                            </ul>
                        </Col>
                        <Col lg={3} md={6} xs={12}>
                            <ul>
                                <li>Bed measurements, 2 x 1900 x 670 singles or 1400 x 1770 sideways double or 1900 x 1770m long ways Queen
                                </li>
                                <li>Dunlop foam squabs</li>
                                <li>Thermal backed curtains</li>
                                <li>Interior walls lined with marine hull-liner</li>
                                <li>Tinted windows</li>
                                <li>Includes all certificates, Gas, Electrics, Self-containment, and Engineering</li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div id="extras" className="vanSection extrasSection">
                <Container>
                    <div className="vanSectionAltHeading">OPTIONAL EXTRAS (AT ADDITIONAL COST)</div>
                    <div className="vanAltSubText">We provide additional features to the standard builds upon request.
                        Some of these include:
                    </div>
                    <Row>
                        <Col lg={4} md={6} xs={12}>
                            <ul>
                                <li>Cabin Heater</li>
                                <li>Tow Bar</li>
                                <li>TV/Satellite Dish</li>
                            </ul>
                        </Col>
                        <Col lg={4} md={6} xs={12}>
                            <ul>
                                <li>Fly Screens</li>
                                <li>Rear sliding window</li>
                            </ul>
                        </Col>
                        <Col lg={4} md={6} xs={12}>
                            <ul>
                                <li>Electric Roof Vent</li>
                                <li>Inverter</li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div id="gallery" className="vanSection gallerySection">
                <Container>
                    <div className="vanSectionBlackHeading">GALLERY</div>
                    <div className="vanBlackSubText">Tap image to enlarge.</div>
                    <AutoGallery images={images}/>
                </Container>
            </div>
        </div>

    )
}

export default Van3;