import {BrowserRouter, Route, Routes, useLocation} from "react-router-dom";

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import About from "./pages/About";
import Footer from "./components/Footer";
import Van1 from "./pages/vans/Van1";
import Van2 from "./pages/vans/Van2";
import Van3 from "./pages/vans/Van3";
import {useEffect, useState} from "react";
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";

function App() {
  const siteKey = "6LdyqpQqAAAAAAu37na9G9vok5GWQtOZmer1chaI";
  
  const [isContactVisible, setIsContactVisible] = useState(false);

  return (
      <GoogleReCaptchaProvider reCaptchaKey={siteKey}>
        <BrowserRouter>
          <NavigationMonitor setIsContactVisible={setIsContactVisible}/>
          <Navbar/>
          <Routes>
            <Route path="/" element={<Home isContactVisible={isContactVisible} setIsContactVisible={setIsContactVisible}/>} />
            <Route path="/campervans" element={<Van1/>} />
            <Route path="/van1" element={<Van1/>} />
            <Route path="/van2" element={<Van2/>} />
            <Route path="/van3" element={<Van3/>} />
            <Route path="/about" element={<About/>} />
          </Routes>
          <Footer/>
        </BrowserRouter>
      </GoogleReCaptchaProvider>
  );
}

const NavigationMonitor = ({setIsContactVisible}) => {
  const location = useLocation();
  useEffect(() => {
    if (location.hash === '#contact') {
      setIsContactVisible(true);
    }
    else
    {
      window.scrollTo(0, 0);
    }
  }, [location]);

  return null;
};

export default App;
