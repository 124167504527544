import 'photoswipe/dist/photoswipe.css'
import React, {useState} from "react";
import { Gallery, Item } from 'react-photoswipe-gallery'
import "./ExpandImage.css"

const ExpandImage = ({ image, altText}) => {
    const [imageDimensions, setImageDimensions] = useState({});

    const onImgLoad = (event) => {
        const img = event.target;
        const { naturalWidth, naturalHeight } = img;
        
        setImageDimensions({ width: naturalWidth, height: naturalHeight });
    };

    const dimensions = imageDimensions || { width: 100, height: 100 };
    
    return (
        <Gallery>
            <Item original={image} width={dimensions.width} height={dimensions.height}>
                {({ ref, open }) => (
                    <img
                        className="expandImage"
                        ref={ref}
                        onClick={open}
                        src={image}
                        onLoad={(e) => onImgLoad(e)}
                        alt={altText}
                    />
                )}
            </Item>
        </Gallery>
    );
};

export default ExpandImage;