import React from "react";

import "./About.css"
import {Col, Container, Row} from "react-bootstrap";
import RetinaImage from "../components/RetinaImage";

import about from "../images/about.png";
import about_l from "../images/about@2x.png";
import {HashLink} from "react-router-hash-link";

function About() {
    return (
        <div className="noOverflow">
            <div className="aboutPageSection">
                <Container>
                    <Row>
                        <Col lg={6} md={12}>
                            <div className="ourStoryText">OUR STORY</div>
                            <div className="ourStorySubText">
                                At West Built RV, we specialise in converting Toyota Hiace vans into fully certified
                                self-contained campervans.
                            </div>
                            <div className="ourStorySubText">
                                Located near Riverhead Forest, northwest of Auckland, our skilled team is dedicated to
                                crafting exceptional campervans tailored to your specific needs. Whether you’re looking
                                for
                                a vehicle for short getaways or have plans for long-term travel, we offer a range of
                                layouts
                                to suit your requirements.
                            </div>
                        </Col>
                        <Col lg={6} md={12}>
                            <RetinaImage smallImage={about} largeImage={about_l} altText="Our story"/>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className="banner aboutBanner">
                <div className="bannerContent">
                    <div className="bannerTitle">VISIT US</div>
                    <div className="bannerText">Come and see our Campervans in person to find out what build is right
                        for you.
                    </div>
                    <HashLink to="/#contact"><div className="wbrButton bannerButton">Enquire now</div></HashLink>
                </div>
            </div>
        </div>
    )
}

export default About;