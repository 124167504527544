import React from "react";
import "./Vans.css"
import VabNavbar from "../../components/VanNavBar";
import RetinaImage from "../../components/RetinaImage";

import {Col, Container, Row} from "react-bootstrap";

import main from "../../images/van1/main.png";
import main_l from "../../images/van1/main@2x.png";
import day_l from "../../images/van1/day@2x.png";
import night_l from "../../images/van1/night@2x.png";
import big from "../../images/van1/big.png";
import big_l from "../../images/van1/big@2x.png";
import AutoGallery from "../../components/AutoGallery";
import ExpandImage from "../../components/ExpandImage";

const images = [
    '/images/van1/WBRV-10052.jpg',
    '/images/van1/WBRV-10060.jpg',
    '/images/van1/WBRV-10068.jpg',
    '/images/van1/WBRV-10075.jpg',
    '/images/van1/WBRV-10127.jpg',
    '/images/van1/WBRV-10136.jpg',
    '/images/van1/WBRV-10144.jpg',
    '/images/van1/WBRV-10153.jpg',
    '/images/van1/WBRV-10159.jpg',
    '/images/van1/WBRV-10162.jpg',
    '/images/van1/WBRV-10180.jpg',
    '/images/van1/WBRV-10194.jpg',
    '/images/van1/WBRV-10197.jpg',
    '/images/van1/WBRV-10204.jpg',
    '/images/van1/WBRV-10239.jpg',
    '/images/van1/WBRV-10246.jpg',
    '/images/van1/WBRV-10268.jpg',
    '/images/van1/WBRV-10281.jpg',
    '/images/van1/WBRV-10296.jpg',
    '/images/van1/WBRV-10302.jpg',
];

function Van1() {
    return (
        <div className="vanWrapper">
            <VabNavbar/>
            <div className="vanMain">
                <RetinaImage smallImage={main} largeImage={main_l} altText="Gen 5 L-Kitchen"/>
            </div>

            <div id="intro" className="vanSection introSection">
                <Container>
                    <div className="vanTitle">Gen 5 L-Kitchen</div>
                    <div className="vanModel">Toyota HiAce Generation 5 Model ZX, Jumbo</div>
                    <div className="vanSubText">The campervan kit can be installed in both new and pre-owned Toyota HiAce models. We offer the option to supply a van for you, or you can bring your own vehicle to us for the installation. The Generation 5 model offers both petrol and diesel options, as well as 2 or 4WD</div>
                </Container>
            </div>

            <div id="layout" className="vanSection layoutSection">
                <Container>
                    <div className="vanSectionHeading">CAMPERVAN LAYOUT</div>
                    <div className="vanSubText">The L-Kitchen design is a fully self-contained unit that excludes the internal shower module, thereby providing additional kitchen space. The toilet is mounted on runners, similar to a drawer system, and complies with the latest self-containment standards.</div>
                    <br/>
                    <div className="vanSubText">The layout of the L-Kitchen includes rear and side overhead lockers as well as a front shelf for storage. Additional storage space is available under the bed bases. The design also features an outdoor shower, crawl-through access between the driver’s seat and the front cabinet and offers multiple options for configuring your sleeping arrangement.</div>
                    <div className="layoutImages">
                        <Row>
                            <Col lg={6} md={12}>
                                <ExpandImage image={day_l} altText="Gen 5 L-Kitchen Day Layout"/>
                            </Col>
                            <Col lg={6} md={12}>
                                <ExpandImage image={night_l} altText="Gen 5 L-Kitchen Night Layout"/>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>

            <div className="vanSection videoSection">
                <RetinaImage smallImage={big} largeImage={big_l} altText="Gen 5 L-Kitchen Inside View"/>
            </div>

            <div id="specs" className="vanSection specsSection">
                <Container>
                    <div className="vanSectionHeading">SPECIFICATIONS</div>
                    <Row>
                        <Col lg={3} md={6} xs={12}>
                            <ul>
                                <li>External Length: 5400mm</li>
                                <li>External Width: 1900mm</li>
                                <li>External Height: 2750mm (includes fibreglass roof)</li>
                                <li>Internal Width: 1740mm</li>
                                <li>Internal Height: 1960mm</li>
                                <li>Sliding cassette toilet</li>
                                <li>Sink with chopping board and cold-water tap</li>
                                <li>Outdoor cold-water shower</li>
                            </ul>
                        </Col>
                        <Col lg={3} md={6} xs={12}>
                            <ul>
                                <li>Dometic hob 2 burner gas with glass opening lid</li>
                                <li>Dometic fridge 80 litre 2-way 240v/12v</li>
                                <li>Double 240v power point</li>
                                <li>195watt solar setup</li>
                                <li>1 x Lithium LiFePo4 Battery with 110Ah</li>
                                <li>Redarc 25amp Charging system, with van DC/DC and MPPT solar, plus a 240v charging system.</li>
                            </ul>
                        </Col>
                        <Col lg={3} md={6} xs={12}>
                            <ul>
                                <li>240v Mains connection with 10-meter power cord</li>
                                <li>Twin USB outlet</li>
                                <li>12v LED lights throughout</li>
                                <li>Tinted Roof vent with fly screen</li>
                                <li>4kg gas bottle</li>
                                <li>12v pressure water pump</li>
                                <li>65 Litre water and 65 Litre waste tanks mounted under van</li>
                                <li>2 Berth</li>
                            </ul>
                        </Col>
                        <Col lg={3} md={6} xs={12}>
                            <ul>
                                <li>Bed measurements, 2 x 620mm x 1900 mm singles or 1300 mm x 1720mm sideways double or 1720mm x 1900mm long ways Queen</li>
                                <li>Dunlop foam squabs</li>
                                <li>Thermal backed curtains</li>
                                <li>Interior walls lined with marine hull-liner</li>
                                <li>Tinted windows</li>
                                <li>All certifying documents, Gas, Electrics, Self-containment, and Engineering</li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div id="extras" className="vanSection extrasSection">
                <Container>
                    <div className="vanSectionAltHeading">OPTIONAL EXTRAS (AT ADDITIONAL COST)</div>
                    <div className="vanAltSubText">We provide additional features to the standard builds upon request. Some of these include:</div>
                    <Row>
                        <Col lg={3} md={6} xs={12}>
                            <ul>
                                <li>Outdoor table</li>
                                <li>Cabin heater</li>
                                <li>Tow bar</li>
                            </ul>
                        </Col>
                        <Col lg={3} md={6} xs={12}>
                            <ul>
                                <li>Fly Screens</li>
                                <li>Rear sliding windows</li>
                                <li>Electric roof vent</li>
                            </ul>
                        </Col>
                        <Col lg={3} md={6} xs={12}>
                            <ul>
                                <li>3m awning</li>
                                <li>TV/Satellite dish</li>
                            </ul>
                        </Col>
                        <Col lg={3} md={6} xs={12}>
                            <ul>
                                <li>Inverter</li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div id="gallery" className="vanSection gallerySection">
                <Container>
                    <div className="vanSectionBlackHeading">GALLERY</div>
                    <div className="vanBlackSubText">Tap image to enlarge.</div>
                    <AutoGallery images={images}/>
                </Container>
            </div>
        </div>

    )
}

export default Van1;