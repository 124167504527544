import 'photoswipe/dist/photoswipe.css'
import React, {useState} from "react";
import { Gallery, Item } from 'react-photoswipe-gallery'
import {Col, Row} from "react-bootstrap";
import "./AutoGallery.css"

const AutoGallery = ({ images }) => {
    const [imageDimensions, setImageDimensions] = useState({});
    
    const onImgLoad = (event, index) => {
        const img = event.target; // The image element
        const { naturalWidth, naturalHeight } = img;
        
        setImageDimensions((prev) => ({
            ...prev,
            [index]: { width: naturalWidth, height: naturalHeight },
        }));
    };

    return (
        <Gallery>
            <Row>
                {images.map((image, index) => {
                    const dimensions = imageDimensions[index] || { width: 100, height: 100 };
                    return (
                        <Col xs={6} md={4} lg={3} key={index}>
                            <Item original={image} width={dimensions.width} height={dimensions.height}>
                                {({ ref, open }) => (
                                    <img
                                        className="thumbnailImage"
                                        ref={ref}
                                        onClick={open}
                                        src={image}
                                        onLoad={(e) => onImgLoad(e, index)}
                                        alt={`Campervan ${index + 1}`}
                                    />
                                )}
                            </Item>
                        </Col>
                    );
                })}
            </Row>
        </Gallery>
    );
};

export default AutoGallery;