import './Navbar.css';
import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import logo from '../logo.svg';
import { HashLink } from 'react-router-hash-link';

function Navbar() {
    const [isMobile, setIsMobile] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 940);
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const toggleMenu = () => {
        setMenuOpen((prev) => !prev);
    };

    const closeMenu = () => setMenuOpen(false);

    const menuItems = [
        { name: 'HOME', href: '/' },
        { name: 'CAMPERVANS', children: [
                { name: 'Gen 5 L-Kitchen', href: '/van1'},
                { name: 'Gen 5 Shower', href: '/van2'},
                { name: 'Gen 6 Shower', href: '/van3'},
            ] },
        { name: 'ABOUT', href: '/about' },
        { name: 'CONTACT', href: '/#contact' },
    ];
    
    return (
        <div className="navbar-outer">
            <nav className="navbar">
                <div className="navbar-container">
                    <Link to="/" className="navbar-logo">
                        <img src={logo} alt="WBR logo" />
                    </Link>

                    {isMobile ? (
                        /* Mobile Version */
                        <>
                            <button className="hamburger" onClick={toggleMenu}>{menuOpen ? '✕' : '☰'}</button>
                            {menuOpen && (
                                <div className="mobile-menu">
                                    {menuItems.map((item) => {
                                            if(item.children)
                                            {
                                                return (
                                                    <li className="mobile-navbar-item">{item.name}
                                                        <ul className="mobile-dropdown-menu">
                                                            {item.children.map((child) => (
                                                                child.href.includes('#') ? (
                                                                    <HashLink to={child.href} onClick={closeMenu}>
                                                                        <li className="mobile-navbar-item">{child.name}</li>
                                                                    </HashLink>
                                                                ) : (
                                                                    <Link to={child.href} onClick={closeMenu}>
                                                                        <li className="mobile-navbar-item">{child.name}</li>
                                                                    </Link>
                                                                )
    
                                                            ))}
                                                        </ul>
                                                    </li>
                                                )
                                            } else {
                                                return (
                                                    item.href.includes('#') ? (
                                                        <HashLink to={item.href} onClick={closeMenu}>
                                                            <li className="mobile-navbar-item">{item.name}</li>
                                                        </HashLink>
                                                    ) : (
                                                        <Link to={item.href} onClick={closeMenu}>
                                                            <li className="mobile-navbar-item">{item.name}</li>
                                                        </Link>
                                                    )
                                                )
                                            }
                                        
                                    })}
                                </div>
                            )}
                        </>
                    ) : (
                        /* Desktop Version */
                        <ul className="navbar-main">
                            {menuItems.map((item) => {
                                if(item.children)
                                {
                                    return (
                                        <li className="navbar-item dropdown">{item.name}
                                            <ul className="dropdown-menu">
                                                {item.children.map((child) => (
                                                    child.href.includes('#') ? (
                                                        <HashLink to={child.href}>
                                                            <li className="dropdown-item">{child.name}</li>
                                                        </HashLink>
                                                    ) : (
                                                        <Link to={child.href}>
                                                            <li className="dropdown-item">{child.name}</li>
                                                        </Link>
                                                    )
                                                    
                                                ))}
                                            </ul>
                                        </li>
                                    )
                                } else {
                                    return (
                                        item.href.includes('#') ? (
                                            <HashLink to={item.href}>
                                                <li className="navbar-item">{item.name}</li>
                                            </HashLink>
                                        ) : (
                                                <Link to={item.href}>
                                                    <li className="navbar-item">{item.name}</li>
                                                </Link>
                                            )
                                    )
                                }
                            })}
                        </ul>
                        )}
                </div>
            </nav>
        </div>
    )
}
export default Navbar;