import "./Home.css"
import {Col, Container, Row} from "react-bootstrap";
import RetinaImage from "../components/RetinaImage";

import cross from "../images/button-cross.png";
import crossl from "../images/button-cross@2x.png";

import camper1 from "../images/camper1.png";
import camper1l from "../images/camper1@2x.png";
import camper2 from "../images/camper2.png";
import camper2l from "../images/camper2@2x.png";
import camper3 from "../images/camper3.png";
import camper3l from "../images/camper3@2x.png";
import {Link} from "react-router-dom";
import {useState} from "react";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";

function Home({isContactVisible, setIsContactVisible}) {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
        message: ""
    });
    const [isDisabled, setIsDisabled] = useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };
    
    const handleOpen = () => {
        setIsContactVisible(true);
        const section = document.getElementById('contact');
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });  // smooth scroll
        }
    };
    const handleClose = () => {
        setTimeout(() => {
            setIsContactVisible(false);
        }, 300);
    };

    // Function to handle reCAPTCHA verification
    const { executeRecaptcha } = useGoogleReCaptcha();

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        if (!executeRecaptcha) {
            alert("reCAPTCHA has not been initialized!");
            return;
        }

        const token = await executeRecaptcha("contact_form"); // Action name
        console.log("reCAPTCHA token:", token);

        if (!token) {
            alert("reCAPTCHA verification failed!");
            return;
        }

        try {
            setIsDisabled(true);
            const response = await fetch("https://vanguardcode.com/wbr/api.php", {
                method: "POST",
                body: JSON.stringify({ formData, token }),
                headers: { "Content-Type": "application/json" },
            });

            const data = await response.json();

            if (data.success) {
                alert("Message sent successfully!");
                setFormData({
                    name: "",
                    email: "",
                    phone: "",
                    message: ""
                });
                setIsDisabled(false);
                handleClose();
            } else {
                alert("Failed to send message!");
            }
        } catch (error) {
            console.error("Error submitting form:", error);
        }
    };
    
    return (
        <div className="Home noOverflow">
            <div className="banner mainBanner">
                <div className="bannerContent">
                    <div className="bannerTitle">
                        <div>EXPLORE THE OPEN ROAD</div>
                        <div>WITH WEST BUILT RV</div>
                    </div>
                </div>
            </div>

            <div className="campervanSection">
                <div className="mainText">Campervans</div>
                <Container>
                    <Row>
                        <Col lg={4} md={12}>
                            <div className="productDisplay">
                                <RetinaImage smallImage={camper1} largeImage={camper1l}
                                             altText={"Gen 5 L-Kitchen"}/>
                                <div className="productText">
                                    <div className="mainDetails">Gen 5 L-Kitchen</div>
                                    <div className="subDetails">Toyota Hiace Gen 5 ZX Jumbo</div>
                                </div>
                                <Link to="/van1"><div className="wbrButton">Find out more</div></Link>
                            </div>
                        </Col>
                        <Col lg={4} md={12}>
                            <div className="productDisplay">
                                <RetinaImage smallImage={camper2} largeImage={camper2l}
                                             altText={"Gen 5 Shower"}/>
                                <div className="productText">
                                    <div className="mainDetails">Gen 5 Shower</div>
                                    <div className="subDetails">Toyota Hiace Gen 5 ZX Jumbo</div>
                                </div>
                                <Link to="/van2"><div className="wbrButton">Find out more</div></Link>
                            </div>
                        </Col>
                        <Col lg={4} md={12}>
                            <div className="productDisplay">
                                <RetinaImage smallImage={camper3} largeImage={camper3l}
                                             altText={"Gen 6 Shower"}/>
                                <div className="productText">
                                    <div className="mainDetails">Gen 6 Shower</div>
                                    <div className="subDetails">Toyota Hiace Gen 6 ZX Jumbo</div>
                                </div>
                                <Link to="/van3"><div className="wbrButton">Find out more</div></Link>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className="aboutSection">
                <Row>
                    <Col lg={6} md={12}>
                        <div className="getInTouch">
                            <div className="getInTouchText">Get in touch with us to view a Campervan</div>
                            <div className="subHeading addressLine">439 Ararimu Valley Rd Waimauku, AKL 0882</div>
                            <hr className="spacer"/>
                            <div className="hoursSection">
                                <div className="subHeading">OPENING HOURS</div>
                                <div className="subDetails">Monday - Friday: 8am - 4.30pm</div>
                                <div className="subDetails">Saturday: By appointment</div>
                                <div className="subDetails">Sunday: Closed</div>
                            </div>
                            <div className="phoneNumber subDetails">Please call <span className="boldText">027 4599 123</span> or <span
                                className="boldText">09 411 9422</span> to make an appointment, or send us an email.
                            </div>
                            <div className="wbrButton" onClick={handleOpen}>Send email</div>
                        </div>
                    </Col>
                    <Col lg={6} md={12}>
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5293.1626093886725!2d174.55442927741325!3d-36.71328457195269!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d0d6b0edf5ec733%3A0xce90d3f9c3b25de9!2sWest%20Built%20Recreational%20Vehicles!5e1!3m2!1sen!2snz!4v1731712654115!5m2!1sen!2snz"
                            width={'100%'} height={'100%'} allowFullScreen="" loading="lazy"
                            title="Google maps location"
                            referrerPolicy="no-referrer-when-downgrade"></iframe>
                    </Col>
                </Row>
            </div>

            <div style={{ overflow: "hidden" }}>
                <div id="contact" className={`enquirySection ${!isContactVisible ? 'slideOut' : ''}`}>
                    <Container>
                        <form onSubmit={handleFormSubmit}>
                            <div className="enquiryBreak">
                                <Row>
                                    <Col md={{ span: 10,  order: 1 }} xs={{ order: 2 }}>
                                        <Row>
                                            <Col xs={12} md={4}>
                                                <div>NAME*</div>
                                                <input name="name" type="text" value={formData.name} onChange={handleInputChange} required/>
                                            </Col>
                                            <Col xs={12} md={4}>
                                                <div>EMAIL*</div>
                                                <input name="email" type="text" value={formData.email} onChange={handleInputChange} required/>
                                            </Col>
                                            <Col xs={12} md={4}>
                                                <div>PHONE*</div>
                                                <input name="phone" type="text" value={formData.phone} onChange={handleInputChange} required/>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md={{ span: 2,  order: 2 }} xs={{ order: 1 }}>
                                        <div className="closeButton">
                                            <a onClick={handleClose}><RetinaImage smallImage={cross} largeImage={crossl} altText="Close enquiry"/></a>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div className="enquiryBreak">
                                <Row>
                                    <Col>
                                        <div>YOUR ENQUIRY*</div>
                                        <textarea name="message" value={formData.message} onChange={handleInputChange}></textarea>
                                    </Col>
                                </Row>
                            </div>
                            <Row>
                                <Col>
                                    <button className="wbrButton enquireButton" type="submit" disabled={isDisabled}>Submit enquiry</button>
                                </Col>
                            </Row>
                        </form>
                    </Container>
                </div>
            </div>

            <div className="banner journeyBanner">
                <div className="bannerContent">
                    <div className="bannerTitle">YOUR JOURNEY STARTS WITH US</div>
                    <Link to="/about"><div className="wbrButton bannerButton">Our story</div></Link>
                </div>
            </div>
        </div>
    )
}

export default Home;