import './VanNavBar.css';
import React from "react";
import { HashLink } from 'react-router-hash-link';
function VabNavbar() { 
    return (
        <>
            <nav className="van-navbar">
                <div className="van-navbar-container">
                    <ul>
                        <HashLink to="#intro">
                            <li>INTRODUCTION</li>
                        </HashLink>
                        <HashLink to="#layout">
                            <li>LAYOUT</li>
                        </HashLink>
                        <HashLink to="#specs">
                            <li>SPECIFICATIONS</li>
                        </HashLink>
                        <HashLink to="#extras">
                            <li>ADDITIONAL FEATURES</li>
                        </HashLink>
                        <HashLink to="#gallery">
                            <li>GALLERY</li>
                        </HashLink>
                    </ul>
                </div>
            </nav>
        </>
    )
}
export default VabNavbar;